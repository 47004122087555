$(document).ready(function () {
  $('.js-switch-price').each(function () {
    const switchUrl = $(this).data('switch-url');
    $(this).find('input:checkbox').on('click', function () {
      $.ajax({
        method: 'post',
        url: switchUrl,
        data: {
          'csrfmiddlewaretoken': getCsrfToken(),
        }
      });
    });
  });
});
